(function ($, window, document, undefined) {
    'use strict';

    /**
     * Flyout Widget
     */
    $.widget('nsm.flyoutTrigger', {
        /**
         * Options
         */
        options: {
            showOnClick: true,
            showOnHover: false,
            hideOnClick: false,
            lockBody: true,
            my: 'left top',
            at: 'left bottom',
            classes: {
                flyoutTriggerActive: 'is-active',
                flyoutVisible: 'is-visible',
                flyoutHidden: 'is-hidden'
            }
        },

        /**
         * Widget Events
         */
        _widgetEvents: {
            click: '_onWidgetClick',
            // mouseenter: 'show',
            // mouseleave: 'hide'
        },

        /**
         * Flyout Events
         */
        _flyoutEvents: {
            click: '_onFlyoutClick',
            // mouseenter: 'show',
            // mouseleave: 'hide'
        },

        /**
         * Window Events
         */
        _windowEvents: {
            click: '_onWindowClick',
            resize: '_onWindowResize'
        },

        _closeEvents: {
            click: 'hide',
        },

        /**
         * Create the widget
         *
         * @private
         */
        _create: function () {

            this.options = $.extend(this.options, this.element.data('flyoutTriggerOptions'));
            this.flyout = $(this.options.flyout);
            this.closeButton = this.flyout.find('.js-dismissTransitional');

            this.flyoutVisible = false;

            this._on(this._widgetEvents);
            this._on(this.flyout, this._flyoutEvents);
            this._on(this.closeButton, this._closeEvents);
            // this.reposition();
        },

        /**
         * Handle Widget Click
         *
         * @param event
         * @private
         */
        _onWidgetClick: function (event) {
            this._toggle(event);
        },

        /**
         * Handle Flyout Click
         *
         * @param event
         * @private
         */
        _onFlyoutClick: function (event) {
            console.log("flyout click");

            if (this.options.hideOnClick) {
                this.hide(event);
            }
        },

        /**
         * Handle Window Click
         *
         * @param event
         * @private
         */
        _onWindowClick: function (event) {
            console.log("caught window click");
            var $target = $(event.target);

            if (this.flyoutVisible && 0 === $target.closest(this.element).length && 0 === $target.closest(this.flyout).length) {
                this.hide(event);
            }
        },

        /**
         * Handle window Resize
         *
         * @private
         */
        _onWindowResize: function () {
            // this.reposition();
        },

        /**
         * Repaint - Reposition the flyout
         */
        reposition: function () {

            if (false === this.flyoutVisible) {
                return;
            }

            $(this.flyout).css({
                top: 0,
                left: 0
            });

            $(this.flyout).position({
                my: this.options.my,
                at: this.options.at,
                of: this.element
            });
        },

        /**
         * Toggle the flyout
         *
         * @param event
         * @private
         */
        _toggle: function (event) {
            this[ this.flyoutVisible ? 'hide' : 'show' ](event);
        },

        /**
         * Show the flyout
         *
         * @param event
         */
        show: function (event) {
            console.log("showing dialog");
            if (true === this.flyoutVisible) {
                return;
            }

            if (this.options.lockBody) {
                console.log("locking body");
                $('html, body').css({
                    'overflow': 'hidden',
                    'height': '100%'
                });
            }

            if (event.type === 'mouseenter' && !this.options.showOnHover) {
                return;
            }

            if (event.type === 'click' && !this.options.showOnClick) {
                return;
            }

            this.flyoutVisible = true;
            // this.reposition();
            this.flyout.show();

            var widget = this;

            setTimeout(function(){
                widget.flyout.addClass(widget.options.classes.flyoutVisible);
            }, 50);
            // this._on($(window), this._windowEvents);
            this._trigger('show', event);
        },

        /**
         * Hide the flyout
         *
         * @param event
         */
        hide: function (event) {
            console.log("hiding dialog");
            if (false === this.flyoutVisible) {
                return;
            }

            if (this.options.lockBody) {
                $('html, body').css({'overflow': 'auto', 'height': 'auto'});
            }

            if(event.type === 'mouseleave' && !this.options.showOnHover) {
                // console.log('not hiding');
                return false;
            }

            // console.log('hiding');
            this.flyoutVisible = false;
            this._off($(window));

            this.flyout.removeClass(this.options.classes.flyoutVisible);

            var widget = this;
            setTimeout(function(){
                widget.flyout.hide();
            }, 500);

            $('.js-drillDownMenu').trigger('resetNav', event);
            this._trigger('hide', event);
        }

    });

})(jQuery, window, document);
