(function($, undefined) {

	$.widget('NSM.container', {

		// all these "global" variables can be called like: this.blah
		// also this.element is the widget instance
		options: {
			animate: false
		},
		elements: {},
		isClosed: false,

		// called once for each element
		_create: function () {
			//console.log('_create NSM.container');
			var self = this,
				options = self.options;
		},

		// called everytime ‘.container()’ is executed on an element.
		_init: function () {
			//console.log('_init NSM.container');
		},

		// Sets a value of the options.
		_setOption: function(key, value) {

			var oldValue = this.options[key] || undefined;

			// Call the base _setOption method
			$.Widget.prototype._setOption.apply(this, arguments);

			// The widget factory doesn't fire an callback for options changes by default
			// In order to allow the user to respond, fire our own callback
			this._trigger("setOption", { type: "setOption" }, {
				option: key,
				original: oldValue,
				current: value
			});
		},

		// Use the destroy method to reverse everything your plugin has applied
		destroy: function() {

			//console.log('_destroy NSM.container');

			this.close();

			for(element in this.elements) {
				this.destroyElement(element);
			}

			// 1. Remove any new elements that you created
			// 2. Unbind any events that may still exist
			// 3. Remove any classes, including CSS framework classes, that you applied
			// After you're done, you still need to invoke the "base" destroy method
			// Does nice things like unbind all namespaced events on the original element
			$.Widget.prototype.destroy.call(this);
		},

		open: function(){
			if(this.options.animate) {
				this.element.slideDown();
			} else {
				this.element.show();
			}
			this.isClosed = false;
		},

		close: function(){
			if(this.options.animate) {
				this.element.slideUp();
			} else {
				this.element.hide();
			}
			this.isClosed = true;
		},

		pushElement: function(el, id){
			//console.log("pushElement", id);
			$el = $(el);
			this.element.append($el);
			this.elements[id] = $el;
		},

		destroyAllElements: function() {
			//console.log("destroyAllElements");
			var self = this;
			$.each(this.elements, function(key, value) { self.destroyElement(key); });
		},

		destroyElement: function(id) {
			//console.log("destroyElement", id);
			if(this.options.animate) {
				this.elements[id].fadeOut({
					complete: this.elements[id].remove()
				});
			} else {
				this.elements[id].remove();
			}

			this._trigger('destroyelement', 0, 
				$.extend(this._uiHash(), {"destroyedElementId" : id})
			);
		},

		showAllElements: function() {
			//console.log("showAllElements");
			var self = this;
			$.each(this.elements, function(key, value) { self.showElement(key); });
		},

		showElement: function(id, hideAll) {
			//console.log("showElement", id);
			if(hideAll) {
				this.hideAll();
			}
			if(this.options.animate) {
				this.elements[id].fadeIn();;
			} else {
				this.elements[id].show();
			}
			this.open();
			this._trigger('showelement', 0, 
				$.extend(this._uiHash(), {"shownElementId" : id})
			);
		},

		hideAllElements: function() {
			//console.log("hideElements");
			var self = this;
			$.each(this.elements, function(key, value) { self.hideElement(key); });
			this._trigger('hide');
		},

		hideElement: function(id) {
			//console.log("hideElement", id);
			if(this.options.animate) {
				this.elements[id].hide();
			} else {
				this.elements[id].hide();
			}
		},
		
		_uiHash: function(inst) {
			var self = inst || this;
			return self;
			//return {};
		}

	});

	$.extend($.NSM.container, {
		version: "0.0.1"
	});

	$.NSM.container.prototype.options.x = 100;

})(jQuery);
