(function($, undefined) {

	$.widget('NSM.tabs', {

		// all these "global" variables can be called like: this.blah
		// also this.element is the widget instance
		options: {
			animate: true,
			selectors: {
				triggers: 'a',
				targetScope: 'body'
			},
			classes: {
				active: 'active'
			}, 
			appendShowAllTab: true,
			buildShowAllTab: function(){
			    return '<li class="filter-show-all"><a href="#all">Show all</a></li>';
			}
		},

        targetScope: false,
		targets: $([]),
		triggers: $([]),

		// called once for each element
		_create: function () {
			console.log('_create NSM.tabs', this);
			var self = this,
				options = self.options;

            if(this.options.appendShowAllTab) {
                this.element.append(this.options.buildShowAllTab());
            }

		},

		// called everytime ‘.tabs()’ is executed on an element.
		_init: function () {
			console.log('_init NSM.tabs');

			this.targetScope = $(this.options.selectors.targetScope);
            this.triggers = $(this.options.selectors.triggers, this.element);

            for (var i = this.triggers.length - 1; i >= 0; i--){
                target = this.targetScope.find(this.triggers[i].hash);
                this.targets = this.targets.add(target);
            };

            this._setupEvents();
		},

		_setupEvents: function(){
		    this.triggers.bind('click', $.proxy(this.tabClick, this));
		},

        tabClick: function(event){

            var trigger = event.target,
                target = (trigger.hash == "#all")
                         ? this.targets
                         : this.targets.hide().filter(trigger.hash);

            if(this.options.animate) {
                target.fadeIn();
            } else {
                target.show();
            }

			this._trigger('click', event, {
				widget:this,
				trigger:trigger,
				target:target
			});

			return false;
        },

		// Sets a value of the options.
		_setOption: function(key, value) {

			var oldValue = this.options[key] || undefined;

			// Call the base _setOption method
			$.Widget.prototype._setOption.apply(this, arguments);

			// The widget factory doesn't fire an callback for options changes by default
			// In order to allow the user to respond, fire our own callback
			this._trigger("setOption", { type: "setOption" }, {
				option: key,
				original: oldValue,
				current: value
			});
		},

		// Use the destroy method to reverse everything your plugin has applied
		destroy: function() {

			console.log('_destroy NSM.tabs');

			this.triggers.unbind('tabsclick');
			this.triggers.filter("[href=#all]").remove();

			// 1. Remove any new elements that you created
			// 2. Unbind any events that may still exist
			// 3. Remove any classes, including CSS framework classes, that you applied
			// After you're done, you still need to invoke the "base" destroy method
			// Does nice things like unbind all namespaced events on the original element
			$.Widget.prototype.destroy.call(this);
		}
	});

})(jQuery);