
(function($) {

	// plugin definition
	$.fn.NSM_publicationMeta = function(options) {
		var opts = $.extend({}, $.fn.NSM_publicationMeta.defaults, options),
			entry_ids = [],
			$elements = this,
			ajaxUrl = opts.url;
		
		$elements = $elements.not("[data-publication-meta-loaded]");
		
		$elements.each(function(i){
			
			var $this = $(this),
				entry_id = false;
			if($this.attr('data-entry-id')){
				entry_id = $this.attr('data-entry-id');
			}else{
				entry_id = $this.parents('article[data-entry-id]').attr('data-entry-id');
				$this.attr('data-entry-id', entry_id);
			}
			
			entry_ids.push(entry_id);
			
		});
		if(entry_ids.length > 0){
			// get the publication details on entry ids
			$.ajax({
				url: ajaxUrl + '/' + entry_ids.join(','),
				type: 'GET',
				dataType: 'json',
				complete: function(xhr, textStatus) {
					//console.log("complete");
				},
				success: function(data, textStatus, xhr) {
					$elements.each(function(){
						var $this = $(this),
							entry_id = $this.attr('data-entry-id');
						if(typeof(data[ entry_id ]) !== 'undefined' ){
							var html = decodeURIComponent(data[ entry_id ]);
							$this.attr('data-publication-meta-loaded', 1).append( html );
						}
					});
				},
				error: function(xhr, textStatus, errorThrown) {
					//console.log("error");
				}
			});
		}
		return this;
	};

	$.fn.NSM_publicationMeta.defaults = {
		"url":"/publications/_widget-entry-publications"
	};

})(jQuery);
