(function ($, window, document, undefined) {
    'use strict';

    /**
     * Flyout Widget
     */
    $.widget('nsm.drillDownMenu', {
        /**
         * Options
         */
        options: {
            classes: {
                focused: "DrillMenu-pane--isFocused",
                offsetLeft: "DrillMenu-pane--offsetLeft",
            },
            selectors: {
                startPane: "#mainNavigation-home",
                allPanes: ".js-DrillMenu-pane",
                pane: ".DrillMenu-pane",
                triggers: '.js-DrillDownMenuLink'
            }
        },


        /**
         * Create the widget
         *
         * @private
         */
        _create: function () {
            this.options = $.extend(this.options, this.element.data('drillDownMenuOptions'));
            this.menuLinks = $(this.widget).find(this.options.selectors.triggers);

            this._on(this.menuLinks, {click: '_onTriggerClick'});
            this._on(this.element, {'resetNav': '_resetNav'});
            // this.reposition();
        },

        /**
         * Handle Widget Click
         *
         * @param event
         * @private
         */
        _onTriggerClick: function (event) {
            event.preventDefault();
            var $link = $(event.currentTarget);
            var $currentPanel = $link.closest(this.options.selectors.pane);
            var targetId = $link.attr('href');
            var $targetPanel = $(targetId);
            if ($targetPanel.hasClass(this.options.classes.offsetLeft)) {
                $currentPanel.removeClass(this.options.classes.focused);
                $targetPanel.removeClass(this.options.classes.offsetLeft).addClass(this.options.classes.focused);
            } else {
                $currentPanel.removeClass(this.options.classes.focused).addClass(this.options.classes.offsetLeft);
                $targetPanel.addClass(this.options.classes.focused);
            }
        },

        _resetNav: function(event) {
            console.log("reseting nav");
            var $allPanes = $(this.options.selectors.allPanes);
            var $homePane = $(this.options.selectors.startPane);
            $allPanes.removeClass(this.options.classes.focused).removeClass(this.options.classes.offsetLeft);
            $homePane.removeClass(this.options.classes.offsetLeft).addClass(this.options.classes.focused);
        }

    });

})(jQuery, window, document);
